export const dateTimeFormat = 'dd. MM. yyyy. HH:mm'
export const dateFormat = 'dd. MM. yyyy.'
export const timeFormat = 'HH:mm'
export const MY_FORMATS = {
    parse: {
      dateInput: 'DD.MM.YYYY',
    },
    display: {
      dateInput: 'DD.MM.YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };