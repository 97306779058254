<div class="">
    <div class="w-full">
        <div class="flex justify-start">
            <img src="assets/images/logo/logo.svg" class="w-12 h-8 mb-3" />
        </div>

        <div class="flex flex-col p-2 w-full items-center mt-1 pb-10">
            <img
                class="w-32 h-32 mb-3 border-2 border-gray-300 rounded-full shadow-lg object-cover"
                [src]="
                    _data.ProfilePhotoThumbnailUrl | userPhoto : _data.Gender
                "
                alt="Bonnie image"
            />

            <h1
                class="mb-4 m-5 text-xl font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-xl"
            >
                <span
                    class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400"
                    >{{ _data.FirstName }}</span
                >
                {{ _data.LastName }}
            </h1>
            <div class="w-full flex px-5 py-2">
                <mat-form-field class="fuse-mat w-full">
                    <mat-select
                        #selectRole
                        matInput
                        [placeholder]="'ChooseStaffPosition' | translate"
                        [(ngModel)]="selectedRole"
                    >
                        <ng-container
                            *ngFor="let institution of groupedRoles | keyvalue"
                        >
                            <mat-optgroup
                                [label]="
                                    institution.value.InstitutionName?.toUpperCase()
                                "
                                class="text-primary-color"
                            >
                                <mat-option
                                    *ngFor="let role of institution.value.Roles"
                                    [value]="role.Id"
                                >
                                    {{ role.Role | translate }}
                                    <ng-container *ngIf="role.UnitName && role.RoleId != roleEnum.Parent">
                                        - {{ role.UnitName }}
                                    </ng-container>
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <button
                class="w-6/12 p-5"
                mat-flat-button
                [color]="'primary'"
                (click)="submitRole(selectRole.value)"
            >
                <span>
                    {{ "SignIn" | translate }}
                </span>
            </button>
        </div>
    </div>
</div>
