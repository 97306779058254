import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnrollmentRequestLogModel, PagedListModel } from 'app/models/all';
import { EnrollmentModel } from 'app/models/enrollment.model';
import {
    EnrollmentDismissRequest,
    EnrollmentGetAllByParentRequest,
    EnrollmentGetIfChildAlreadyExistsRequest,
    EnrollmentLogsInsertRequest,
    EnrollmentRequestGetDataById,
    EnrollmentsDeleteRequest,
    EnrollmentsGetByChildId,
    EnrollmentsGetByParentIdRequest,
    EnrollmentsGetNonExtended,
    EnrollmentsGetRequest,
    EnrollmentsInsertRequest,
    GetByIdRequest,
} from 'app/models/requests/all';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { EnrollmentGetAllByParentModel } from 'app/models/enrollment-by-parent.model';
import { EnrollmentReportDataModel } from 'app/models/enrollment-report-data.model';
import { EnrollmentCheckKidExistsModel } from 'app/models/enrollment-check-kid-exists.model';
@Injectable({
    providedIn: 'root',
})
export class EnrollmentsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getDataById(request: EnrollmentRequestGetDataById) {
        return this.sendGetRequest<any>(
            'enrollments/getbyenrollmentinfo',
            request
        );
    }
    get(request: EnrollmentsGetRequest) {
        return this.sendGetRequest<PagedListModel<EnrollmentModel>>(
            'enrollments/filter',
            request
        );
    }

    getByChildId(request: EnrollmentsGetByChildId) {
        return this.sendGetRequest<EnrollmentModel[]>(
            'enrollments/getbychildid',
            request
        );
    }

    getLatestByChildId(childId: number) {
        return this.sendGetRequest<EnrollmentModel>(
            `enrollments/latestenrollment?childId=${childId}`
        );
    }

    getMessage(request: GetByIdRequest): Observable<any> {
        return this.sendGetRequest<any>(
            'enrollments/getenrollmentstatusesbyparentid',
            request
        );
    }

    insert(request: EnrollmentsInsertRequest) {
        const params: any = request?.toParams();
        const body: any = request?.toBody();
        return this.httpClient.post<any>(
            this.withBaseApiUrl('enrollments'),
            body,
            {
                headers: this.prepareHeaders(),
                params: params,
            }
        );
    }

    renew(request: EnrollmentsInsertRequest) {
        return this.sendPostRequest<{ message: string }>(
            'enrollments/renew',
            request
        );
    }

    insertRequestLog(request: EnrollmentLogsInsertRequest) {
        return this.sendPostRequest<EnrollmentRequestLogModel>(
            'enrollments/insertenrollmentrequestlog',
            request
        );
    }

    delete(request: EnrollmentsDeleteRequest) {
        return this.sendDeleteRequest('enrollments', request);
    }

    getByParentId(request: EnrollmentsGetByParentIdRequest) {
        return this.sendGetRequest<EnrollmentModel[]>(
            'enrollments/getbyparentid',
            request
        );
    }

    getAllByParent(request: EnrollmentGetAllByParentRequest) {
        return this.sendGetRequest<EnrollmentGetAllByParentModel[]>(
            'enrollments/getallbyparent',
            request
        );
    }

    cancelEnrollmentRequest(enrollmentId: number) {
        return this.sendPostRequest(
            'enrollments/cancel-request?enrollmentId=' + enrollmentId
        );
    }

    getEnrollmentReportData(enrollmentId: number) {
        return this.sendGetRequest<EnrollmentReportDataModel[]>(
            'enrollments/get-detailed-for-report?enrollmentId=' + enrollmentId
        );
    }

    getKidAlreadyExists(request: EnrollmentGetIfChildAlreadyExistsRequest) {
        return this.sendGetRequest<EnrollmentCheckKidExistsModel | null>(
            'enrollments/check-if-kid-exists',
            request
        );
    }

    dismiss(request: EnrollmentDismissRequest){
        return this.sendPostRequest('enrollments/dismiss', request);
    }

    checkEnrollmentDismissed(childId: number){
        return this.sendGetRequest<any>('enrollments/check-enrollment-dismissed?childId=' + childId);
    }

    getNonExtendedEnrollments(request: EnrollmentsGetNonExtended) {
        return this.sendGetRequest<EnrollmentModel[]>('enrollments/getnonextendedenrollments', request);
    }
}
