import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';

import { ApiService, StorageService } from './all';
import {
    SimpleIdRequest,
    PreschoolGroupGetDataIdByParamRequest,
    PreschoolGroupInsertRequest,
    PreschoolGroupsGetByDataIdRequest,
    PreschoolGroupsGetByPreschoolIdRequest,
    PreschoolGroupsGetRequest,
    PreschoolGroupsDeleteRequest,
    PreschoolGroupsUpdateRequest,
    GetByIdRequest,
    PreschoolGroupEmployeeGetEducatorForChildRequest,
    PreschoolGroupsGetByBusinessUnitIdRequest,
    PreschoolGroupsForSchoolYearRequest,
    PreschoolGroupByBusinessUnitForEducatorRequest,
    PreschoolGroupsForYearAndMonthRequest,
} from 'app/models/requests/all';
import {
    EmployeeModel,
    PagedListModel,
    PreschoolGroupDataModel,
    PreschoolGroupModel,
    SimpleItemModel,
} from 'app/models/all';

@Injectable({
    providedIn: 'root',
})
export class PreschoolGroupsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: PreschoolGroupsGetRequest) {
        return this.sendGetRequest<PagedListModel<PreschoolGroupModel>>(
            'preschoolgroups/filter',
            request
        );
    }

    getByPreschoolId(request: PreschoolGroupsGetByPreschoolIdRequest) {
        return this.sendGetRequest<PreschoolGroupModel[]>(
            'preschoolgroups/getgroupsbypreschoolid',
            request
        );
    }

    getByBusinessUnitId(request: PreschoolGroupsGetByBusinessUnitIdRequest) {
        return this.sendGetRequest<PreschoolGroupModel[]>(
            'preschoolgroups/getgroupsbybusinessunitid',
            request
        );
    }

    getForEducator() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'preschoolgroups/getgroupsforeducator'
        );
    }

    getDataByGroupId(request: SimpleIdRequest) {
        return this.sendGetRequest<PreschoolGroupDataModel>(
            'preschoolgroups/getdatabygroupid',
            request
        );
    }

    getByPreschoolGroupDataId(request: PreschoolGroupsGetByDataIdRequest) {
        return this.sendGetRequest<PreschoolGroupModel>(
            'preschoolgroups/getgroupbypreschoolgroupdataid',
            request
        );
    }

    getSchoolYears() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'preschoolgroups/getschoolyears'
        );
    }

    extractDataId(request: PreschoolGroupGetDataIdByParamRequest) {
        return this.sendGetRequest<number>(
            'preschoolgroups/extractdataid',
            request
        );
    }

    getSimpleItem(request: GetByIdRequest) {
        return this.sendGetRequest<SimpleItemModel[]>(
            'preschoolgroups/getsimpleitempreschoolgroups',
            request
        );
    }

    getEducatorsForChild(
        request: PreschoolGroupEmployeeGetEducatorForChildRequest
    ) {
        return this.sendGetRequest<EmployeeModel[]>(
            'preschoolgroups/geteducatorsforchild',
            request
        );
    }

    getGroupIdsForEducator(request: GetByIdRequest) {
        return this.sendGetRequest<number[]>(
            'preschoolgroups/getgroupidsforeducator',
            request
        );
    }

    getGroupsForSchoolYear(request: PreschoolGroupsForSchoolYearRequest) {
        return this.sendGetRequest<PreschoolGroupModel>(
            'preschoolgroups/getgroupsforschoolyear',
            request
        );
    }

    getGroupByBusinessUnitForEducator(
        request: PreschoolGroupByBusinessUnitForEducatorRequest
    ) {
        return this.sendGetRequest<SimpleItemModel[]>(
            'preschoolgroups/getgroupbybusinessunitforeducator',
            request
        );
    }

    getGroupByPreschoolId(request: GetByIdRequest) {
        return this.sendGetRequest<SimpleItemModel[]>(
            'preschoolgroups/getgroupbyinstitution',
            request
        );
    }

    getGroupsForYearAndMonth(request: PreschoolGroupsForYearAndMonthRequest){
        return this.sendGetRequest<PreschoolGroupModel[]>(
            'preschoolgroups/getgroupsforyearandmonth',
            request
        );
    }

    insert(request: PreschoolGroupInsertRequest) {
        return this.sendPostRequest<any>('preschoolgroups', request);
    }

    update(request: PreschoolGroupsUpdateRequest) {
        return this.sendPutRequest<any>('preschoolgroups', request);
    }

    delete(request: PreschoolGroupsDeleteRequest) {
        return this.sendDeleteRequest<any>('preschoolgroups', request);
    }
}
