import { NgModule } from '@angular/core';
import { SnackbarComponent } from './snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [MatIconModule, CommonModule],
    declarations: [SnackbarComponent],
    exports: [SnackbarComponent],
})
export class SnackbarModule {}
