import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Observable, Subject } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { Select, Store } from '@ngxs/store';
import { AuthState, AuthStateModel } from 'app/stores/all';
import { ChooseRolesComponent } from 'app/modules/auth/roles/choose-roles/choose-roles.component';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from 'app/services/storage.service';
import { AspNetUserRoles } from 'app/models/user.model';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */
    @Input() showAvatar = true;
    @Select(AuthState) user$: Observable<AuthStateModel>;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        public _storageService: StorageService,
        private _router: Router,
        private _userService: UserService,
        private _dialog: MatDialog,
        private _store: Store
    ) {}

    @Select(AuthState.details) details$: Observable<any>;
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    _currentRole: AspNetUserRoles = null;
    /**
     * On init dont leave pc unlocked
     */
    ngOnInit(): void {
        this.user$.subscribe((data: AuthStateModel) => {
            this.user = data.details;
        });
        const roles = this._store.selectSnapshot(
            AuthState.details
        ).AspNetUserRoles;
        const role = roles.find(
            (x) =>
                x.InstitutionId ==
                    Number(this._storageService.getInstitutionId()) &&
                x.Role?.toLowerCase() == this._storageService.getActiveRole()
        );

        this._currentRole = role;
        this.user = this._store.selectSnapshot(AuthState.details);
    }
    getTrueVal(value: number): number {
        if (value == 0 || !value) return null;
        else return value;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService
            .update({
                ...this.user,
            })
            .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    navigate(link: string): void {
        this._router.navigate([link]);
    }

    /**
     * Change user role
     */

    changeUserRole() {
        this._dialog.open(ChooseRolesComponent, {
            data: this.user,
            enterAnimationDuration: '500ms',
            width: '450px',
            maxWidth: '99%',
        });
    }
}
