import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ApiService, StorageService } from './all';
import {
    GetDocumentsForChildRequest,
    PersonFileStorageCopyFilesRequest,
    PersonFileStorageGetAvailableRequest,
    PersonFileStorageInsertRequest,
} from '../models/requests/all';
import { Observable } from 'rxjs';
import { PersonFileStorageAlreadyDocumented } from 'app/models/person-file-storage-already-documented.model';

@Injectable({ providedIn: 'root' })
export class UploadService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    insertfilestorage(request: PersonFileStorageInsertRequest) {
        return this.sendPostRequest<any>('personfilestorage', request);
    }

    getdocumentsforchild(
        request: GetDocumentsForChildRequest
    ): Observable<any> {
        return this.sendGetRequest<any>(
            'personfilestorage/getdocumentsforchild',
            request
        );
    }

    getAlreadyDocumentedFiles(request: PersonFileStorageGetAvailableRequest) {
        return this.sendGetRequest<PersonFileStorageAlreadyDocumented[]>(
            'personfilestorage/getavailabledocuments',
            request
        );
    }

    copyPreviousFiles(request: PersonFileStorageCopyFilesRequest) {
        return this.sendPostRequest<any>(
            'personfilestorage/copyprevious',
            request
        );
    }
}
