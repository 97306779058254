import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    AuthStateModel,
    AuthSignIn,
    AuthSignUp,
    AuthForgotPassword,
    AuthChangePassword,
    AuthSignOut,
    AuthRefresh,
    AuthProfilePhotoRefresh,
    AuthReset,
    AuthSetNewToken,
    AuthSetLoggedUser,
} from './auth.actions';
import { AuthModel, UserModel } from 'app/models/all';
import { AuthService, TokenService } from 'app/services/all';

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        token: undefined,
        details: undefined,
    },
})
@Injectable()
export class AuthState {
    constructor(
        private authService: AuthService,
        private tokenService: TokenService
    ) {}

    @Selector()
    static token(state: AuthStateModel): string | undefined {
        return state.token;
    }

    @Selector()
    static details(state: AuthStateModel): UserModel | undefined {
        return state.details;
    }

    @Action(AuthSignIn)
    signIn(context: StateContext<AuthStateModel>, params: AuthSignIn) {
        return this.authService.signIn(params.request).pipe(
            // After successfully signing in, switch to the getLoggedUser observable
            switchMap((result: AuthModel) => {
                // First, update the token in the state and set the refresh token
                context.patchState({
                    token: result.token
                });
                this.authService.setRefreshToken(result.refreshToken);

                // Return the getLoggedUser observable, waiting for its completion
                return this.authService.getLoggedUser();
            }),
            // When getLoggedUser completes, update the details in the state
            tap((userDetails: UserModel) => {
                context.patchState({
                    details: userDetails
                });
            })
    );
}

    @Action(AuthSetNewToken)
    setNewToken(context: StateContext<AuthStateModel>, params: any) {
        context.patchState({
            token: params.token
        });
    }

    @Action(AuthSignOut)
    signOut(context: StateContext<AuthStateModel>, params: AuthSignOut) {
        context.patchState({
            token: undefined,
            details: undefined,
        });
        this.authService.clearRefreshToken();
    }

    @Action(AuthSignUp)
    signUp(context: StateContext<AuthStateModel>, params: AuthSignUp) {
        return this.authService.signUp(params.request);
    }

    @Action(AuthSetLoggedUser)
    setLoggedUser(context: StateContext<AuthStateModel>, params: AuthSetLoggedUser) {
        context.patchState({
            details: params.details
        });
    }

    @Action(AuthForgotPassword)
    forgotPassword(
        context: StateContext<AuthStateModel>,
        params: AuthForgotPassword
    ) {
        return this.authService.forgotPassword(params.request);
    }

    @Action(AuthChangePassword)
    changePassword(
        context: StateContext<AuthStateModel>,
        params: AuthChangePassword
    ) {
        return this.authService.changePassword(params.request);
    }

    @Action(AuthRefresh)
    authRefresh(context: StateContext<AuthStateModel>, params: AuthRefresh) {
        context.patchState({
            details: params.updated,
        });
    }

    @Action(AuthProfilePhotoRefresh)
    profilePhotoRefresh(
        context: StateContext<AuthStateModel>,
        params: AuthProfilePhotoRefresh
    ) {
        params.updated.ProfilePhotoUrl = params.url;
        params.updated.ProfilePhotoThumbnailUrl = params.thumbnailUrl;

        context.patchState({
            details: params.updated,
        });
    }

    @Action(AuthReset)
    resetState(context: StateContext<AuthStateModel>){
        context.setState({
            token: undefined,
            details: undefined,
          });
    }
}
