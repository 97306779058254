<div class="flex items-center">
    <img
        class="w-8 h-8"
        [src]="getThumbnailOfActivity(model.announcementType)"
        alt="Card cover image"
    />
    <div class="flex-auto mx-2 w-full">
        <p
            class="announcementStyle text-sm sm:text-xl font-bold text-xl"
            style="margin-bottom: 0.1rem"
        >
            {{ model?.title }}
        </p>
        <p
            class="text-sm flex mb-0 items-center leading-none announcementStyle"
        >
            {{ model?.createdAt | dateFormat }}
        </p>
    </div>
    <div class="flex-auto flex justify-end text">
        <button (click)="closeDialog()" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</div>

<hr />
<div class="m-1">
    <p class="text-lg text-secondary">
        {{ model.shortly }}
    </p>
</div>

<div class="m-1">
    <p
        [innerHTML]="model.text | cleanHtml"
        class="text-lg announcementStyle"
    ></p>
</div>

<div class="grid gap-4" *ngIf="model.files.length > 0">
    <div class="flex items-center mt-3">
        <p class="mb-0 d-flex items-center">
            <mat-icon
                class="w-5 h-5"
                [svgIcon]="'heroicons_outline:paper-clip'"
            ></mat-icon>
        </p>
        <div class="ml-2 font-semibold">
            {{ model.files.length }}
            {{
                (model.files.length > 1 ? "AttachmentsAnn" : "AttachmentAnn")
                    | translate
            }}
        </div>
    </div>
    <div
        class="grid grid-flow-row-dense gap-4 grid-cols-1 sm:grid-cols-3 py-2 pe-2"
    >
        <div
            (click)="openDetails(model?.files, item.absouluteUrl)"
            class="flex justify-content-start p-2 cursor-pointer"
            *ngFor="let item of model?.fullFiles; let i = index"
        >
            <img
                *ngIf="
                    item.absouluteUrl.includes('jpg') ||
                        item.absouluteUrl.includes('png') ||
                        item.absouluteUrl.includes('jpeg') ||
                        item.absouluteUrl.includes('jfif') ||
                        item.absouluteUrl.includes('avif');
                    else filePic
                "
                class="w-10 h-10 rounded-md"
                [src]="item.absouluteUrl"
                alt=""
            />

            <ng-template #filePic>
                <img
                    class="w-10 h-10 rounded-md"
                    src="assets/img/annoucements/open-folder.png"
                />
            </ng-template>

            <div class="ml-3 mr-3">
                <div
                    class="text-md font-medium truncate w-28"
                    title="{{ item.fileName }}"
                >
                    {{ item.fileName }}
                </div>
                <div
                    class="text-sm font-medium truncate text-secondary"
                    *ngIf="item.fileLength != null"
                >
                    {{ item.fileLength.toString() | fileSizeConverter }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="flex w-full justify-content-between items-center mt-2">
    <div class="flex-auto flex items-center">
        <img
            class="w-6 h-6 mr-2 rounded-full photo-cover"
            [src]="model.userProfilePhotoThumbnailUrl"
        />

        <span class="text-md font-bold announcementStyle">
            {{ model?.userFullName }}
        </span>
    </div>
</div>
