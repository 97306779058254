import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { CityModel, PagedListModel, SimpleItemModel } from '../models/all';
import { ApiService, StorageService } from './all';
import {
    CitiesGetRequest,
    CitiesInsertRequest,
    CitiesUpdateRequest,
    CitiesDeleteRequest,
    CitiesUsedGetRequest,
} from '../models/requests/all';

@Injectable({
    providedIn: 'root',
})
export class CitiesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: CitiesGetRequest) {
        return this.sendGetRequest<PagedListModel<CityModel>>(
            'cities/filter',
            request
        );
    }

    getById(request: CitiesGetRequest) {
        return this.sendGetRequest<CityModel>('cities', request);
    }

    getSelectList() {
        return this.sendGetRequest<SimpleItemModel[]>('cities/select-list');
    }

    insert(request: CitiesInsertRequest) {
        return this.sendPostRequest<CityModel>('cities', request);
    }

    update(request: CitiesUpdateRequest) {
        return this.sendPutRequest('cities', request);
    }

    delete(request: CitiesDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('cities', request);
    }

    getSearchableDropdownList(request: CitiesGetRequest) {
        return this.sendGetRequest<PagedListModel<CityModel>>(
            'cities/getsearchablelist',
            request
        );
    }

    getUsedCities(city: CitiesUsedGetRequest) {
        return this.sendGetRequest<CityModel[]>(
            'cities/getusedcities', city
        );
    }
}
