<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation
    *ngIf="isScreenSmall"
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigationFixed"
    [opened]="false"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-8" [src]="logoTextUrl" />
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <notifications></notifications>
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img
                    class="w-full object-cover h-full rounded-full photo-cover"
                    *ngIf="user.ProfilePhotoThumbnailUrl"
                    [src]="user.ProfilePhotoThumbnailUrl"
                    alt="User avatar"
                />
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!user.ProfilePhotoThumbnailUrl"
                    [svgIcon]="'heroicons_solid:user-circle'"
                ></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium"
                >
                    {{ user.FullName }}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
                >

                    <ng-template>
                        <span>{{ user.Email }}</span>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div
            class="grid grid-cols-1 items-center h-8 pr-6 pl-2 mt-6 mb-4 opacity-12"
        >
            <div class="flex justify-center">
                <img
                    class="max-w-20 bottom-0 pointer-events-none"
                    [src]="logoTextUrl"
                />
            </div>
            <span
                class="w-full flex justify-center text-md bottom-0 pointer-events-none"
            >
                Breakpoint &copy; {{ currentYear }}</span
            >
        </div>
    </ng-container>
</fuse-vertical-navigation>
<div
    class="flex flex-auto justify-center w-full sm:p-4 md:p-8 bg-gray-200 dark:bg-card"
>
    <div
        class="flex flex-col items-center flex-auto min-w-0 max-w-360 sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden"
    >
        <!-- Header -->
        <div
            class="relative flex flex-0 items-center w-full h-16 sm:h-20 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden"
        >
            <ng-container *ngIf="!isScreenSmall">
                <!-- Logo -->
                <div class="flex items-center mx-2 lg:ml-5 lg:mr-8">
                    <div class="hidden lg:flex">
                        <!-- Light version -->
                        <button
                            [routerLink]="
                                !displaySpecificHeaderStuff
                                    ? ['../waiting-list']
                                    : ['../home']
                            "
                        >
                            <img
                                class="dark:hidden w-10"
                                [src]="logoTextUrl"
                                alt="Logo image"
                            />
                        </button>
                        <!-- Dark version -->
                        <button
                            [routerLink]="
                                !displaySpecificHeaderStuff
                                    ? ['../waiting-list']
                                    : ['../home']
                            "
                        >
                            <img
                                class="hidden dark:flex w-10"
                                [src]="logoTextUrl"
                                alt="Logo image"
                            />
                        </button>
                    </div>
                    <!-- Small version -->
                    <button
                        [routerLink]="
                            !displaySpecificHeaderStuff
                                ? ['../waiting-list']
                                : ['../home']
                        "
                    >
                        <img
                            class="flex lg:hidden w-8"
                            [src]="logoTextUrl"
                            alt="Logo image"
                        />
                    </button>
                </div>
                <!-- Horizontal navigation (prikazuje navigation iz side menu-a na horizontalnoj traci - zakomentarisano zbog profila roditelja)-->
                <!-- <fuse-horizontal-navigation
                    class="mr-2"
                    [name]="'mainNavigation'"
                    [navigation]="navigation?.horizontal"
                ></fuse-horizontal-navigation> -->
            </ng-container>
            <ng-container *ngIf="isScreenSmall">
                <div class="flex items-center mx-2 lg:ml-5 lg:mr-8">
                    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                    </button>
                </div>
            </ng-container>
            <!-- Navigation toggle button -->
            <!-- <ng-container *ngIf="!isScreenSmall">
                <button
                    class="mr-2 ml-5"
                    mat-icon-button
                    [routerLink]="
                        !displaySpecificHeaderStuff
                            ? ['../waiting-list']
                            : ['../home']
                    "
                >
                    <img
                        class="flex lg:hidden w-8"
                        [src]="logoTextUrl"
                        alt="Logo image"
                    />
                </button>
            </ng-container> -->
            <!-- Components -->
            <div *ngIf="!isScreenSmall"
                class="flex items-center pl-2 pe-2 ml-auto space-x-1 sm:space-x-2"
            >
                <a
                    mat-icon-button
                    [routerLink]="
                        !displaySpecificHeaderStuff
                            ? ['../waiting-list']
                            : ['../home']
                    "
                    ><mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
                </a>
                <a
                    *ngIf="displaySpecificHeaderStuff"
                    mat-icon-button
                    [routerLink]="['../calendar']"
                    ><mat-icon
                        [svgIcon]="'heroicons_outline:calendar'"
                    ></mat-icon>
                </a>
                <a
                    mat-icon-button
                    [routerLink]="['../parents-children']"
                    ><mat-icon [svgIcon]="'heroicons_outline:user-group'"></mat-icon>
                </a>
                <a
                    mat-icon-button
                    [routerLink]="['../waiting-list']"
                    ><mat-icon [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
                </a>
                <messages *ngIf="displaySpecificHeaderStuff"></messages>
                <notifications
                    *ngIf="displaySpecificHeaderStuff"
                ></notifications>

                <languages></languages>
                <user></user>
            </div>

            <div *ngIf="isScreenSmall"
                class="flex items-center pl-2 pe-2 ml-auto space-x-1 sm:space-x-2 mr-2 sm:mr-0"
            >
                <messages *ngIf="displaySpecificHeaderStuff"></messages>
                <languages></languages>
            </div>
        </div>

        <!-- Content -->
        <div class="flex flex-col flex-auto w-full bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>

        <!-- Footer -->
        <div
            class="relative flex flex-0 items-center justify-start w-full h-16 sm:h-20 px-6 sm:px-8 z-49 bg-card border-t dark:bg-default print:hidden"
        >
            <span class="font-medium text-secondary"
                >Breakpoint &copy; {{ currentYear }}</span
            >
        </div>
    </div>
</div>
