import { Pipe, PipeTransform } from '@angular/core';
import { femaleUserPhotoUrl, maleUserPhotoUrl } from 'app/constants/constants';
import { GenderEnum } from 'app/models/enums/gender.enum';

@Pipe({
    name: 'userPhoto',
})
export class UserPhotoPipe implements PipeTransform {
    transform(avatar: string, gender: GenderEnum): string {
        if (avatar) {
            return avatar;
        }
        return gender == GenderEnum.Male
                ? maleUserPhotoUrl
                : femaleUserPhotoUrl;
    }
}
