import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { ApiService, StorageService } from './all';
import { Injectable } from '@angular/core';
import { SimpleItemModel } from 'app/models/simple-item.model';
import {
    InstitutionConfigModel,
    PagedListModel,
    SchoolYearModel,
} from 'app/models/all';
import {
    SchoolYearsDeleteRequest,
    SchoolYearsInsertRequest,
    SchoolYearsUpdateRequest,
} from 'app/models/requests/all';

@Injectable({ providedIn: 'root' })
export class SchoolYearsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getAllYears() {
        return this.sendGetRequest<SchoolYearModel[]>(
            'schoolyears/getallyears'
        );
    }

    postSchoolYear(request: SchoolYearsInsertRequest) {
        return this.sendPostRequest('schoolyears', request);
    }

    updateSchoolYear(request: SchoolYearsUpdateRequest) {
        return this.sendPutRequest('schoolyears', request);
    }

    removeSchoolYear(request: SchoolYearsDeleteRequest) {
        return this.sendDeleteRequest('schoolyears', request);
    }
}
