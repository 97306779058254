import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { ActiveRoleEnum } from 'app/models/enums/roles.enum';
import { AuthSignOut, AuthState } from 'app/stores/all';
import { StorageService } from './storage.service';
import { EnrollmentsService } from './enrollments.service';

import { GetByIdRequest } from 'app/models/requests/by-id.request';
import { MatDialog } from '@angular/material/dialog';
import { QuestionModalComponent } from 'app/modules/auth/sign-in/question-modal/question-modal.component';
import { activeRole } from 'app/constants/constants';
import { SnackBarService } from './snack-bar.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationRoleService {
    constructor(
        private _storageService: StorageService,
        private _router: Router,
        private _navigationApi: NavigationMockApi,
        private _store: Store,
        private _enrollmentsService: EnrollmentsService,
        private _snackBarService: SnackBarService,
        private _dialog: MatDialog
    ) {}

    getRoleNavigation() {
        const activeRoleItem = this._storageService
            .getItem(activeRole)
            ?.toLocaleLowerCase()
            ?.slice(1, -1);

        let redirectUrl: string | null = null;
        let isLoading = false;

        if (activeRoleItem === ActiveRoleEnum.SuperAdministrator) {
            redirectUrl = 'dashboard';
        }

        if (
            activeRoleItem === ActiveRoleEnum.PreschoolInstitutionAdministrator
        ) {
            redirectUrl = 'home';
        }

        if (
            activeRoleItem === 'parent' ||
            activeRoleItem == null ||
            activeRoleItem === ''
        ) {
            isLoading = true;
            this._enrollmentsService
                .getMessage(
                    new GetByIdRequest(
                        this._store.selectSnapshot(AuthState.details).Id
                    )
                )
                .subscribe((data) => {
                    if (data.message.length > 1) {
                        if (data.message === 'redirect-to-new-enrollment') {
                            redirectUrl = 'newenrollment';
                        } else if (
                            data.message === 'redirect-to-waiting-list'
                        ) {
                            redirectUrl = 'waiting-list';
                        } else if (data.message !== '' && data.dialog) {
                            this._dialog.open(QuestionModalComponent, {
                                data: data.message,
                            });
                        } else {
                            this._snackBarService.showSnackBar(data.message);
                            this._store.dispatch(new AuthSignOut());
                            redirectUrl = 'sign-in';
                        }
                    } else {
                        const countOfChildren = this._store.selectSnapshot(
                            AuthState.details
                        ).Children.length;
                        if (countOfChildren > 0) {
                            redirectUrl = 'home';
                        }
                    }
                    this._router.navigateByUrl(redirectUrl || 'home'); // Default to 'home' if no redirectUrl is set
                    isLoading = false;
                });
        }

        // Handle role-based redirects if no async operation is triggered
        if (!isLoading) {
            if (!activeRoleItem) {
                redirectUrl = 'newenrollment';
            } else if (
                activeRoleItem === ActiveRoleEnum.PreschoolInstitutionManager
            ) {
                redirectUrl = 'home';
            } else if (activeRoleItem === ActiveRoleEnum.Educator) {
                redirectUrl = 'home';
            }
            this._router.navigateByUrl(redirectUrl || 'home'); // Fallback in case no condition is met
        }

        // Register handlers
        this._navigationApi.registerHandlers();
        if (this._router.url.includes('home')) {
            window.location.reload();
        }
    }
}
