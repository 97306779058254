import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EmployeeModel } from '../models/all';
import { ApiService, StorageService } from './all';
import { EmployeeSetCoordinatorRequest } from 'app/models/requests/employee.request';

@Injectable({ providedIn: 'root' })
export class EmployeesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getById(id: number) {
        return this.sendGetRequest<EmployeeModel>('employees?id=' + id);
    }

    getPublic(preschoolInstitutionId: number) {
        return this.sendGetRequest<EmployeeModel[]>(
            'employees/getpublicinformation?preschoolInstitutionId=' +
                preschoolInstitutionId
        );
    }

    isCoordinator() {
        return this.sendGetRequest<boolean>('employees/iscoordinator');
    }

    isUserCoordinator(userId: number) {
        return this.sendGetRequest<boolean>(
            'employees/isusercoordinator?userId=' + userId
        );
    }

    setCoordinator(request: EmployeeSetCoordinatorRequest) {
        return this.sendPutRequest('employees/setcoordinator', request);
    }
}
