import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
    BusinessUnitsStateModel,
    BusinessUnitsGet,
    BusinessUnitsUpdate,
    BusinessUnitsDelete,
    BusinessUnitsGetById,
    BusinessUnitsGetByUserId,
    BusinessUnitsGetSelectList,
} from './business-units.actions';
import {
    BusinessUnitModel,
    PagedListModel,
    SimpleItemModel,
} from 'app/models/all';
import { BusinessUnitsGetRequest } from 'app/models/requests/business-units.request';
import { BusinessUnitsService } from 'app/services/business-units.service';

@State<BusinessUnitsStateModel>({
    name: 'businessUnits',
    defaults: {},
})
@Injectable()
export class BusinessUnitsState {
    constructor(private businessunitsService: BusinessUnitsService) {}

    @Selector()
    static businessUnits(
        state: BusinessUnitsStateModel
    ): PagedListModel<BusinessUnitModel> | undefined {
        return state.businessUnits;
    }

    @Selector()
    static businessUnitsSelectList(
        state: BusinessUnitsStateModel
    ): Array<SimpleItemModel> | undefined {
        return state.businessUnitsSelectList;
    }

    @Action(BusinessUnitsGet)
    get(
        context: StateContext<BusinessUnitsStateModel>,
        params: BusinessUnitsGet
    ) {
        return this.businessunitsService.get(params.request).pipe(
            tap((result: PagedListModel<BusinessUnitModel>) => {
                context.patchState({
                    businessUnits: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(BusinessUnitsGetSelectList)
    getSelectList(
        context: StateContext<BusinessUnitsStateModel>,
        params: BusinessUnitsGetSelectList
    ) {
        return this.businessunitsService.getSelectList(params.request).pipe(
            tap((result: Array<SimpleItemModel>) => {
                context.patchState({
                    businessUnitsSelectList: result,
                });
            })
        );
    }

    @Action(BusinessUnitsGetByUserId)
    getByUserId(
        context: StateContext<BusinessUnitsStateModel>,
        params: BusinessUnitsGetByUserId
    ) {
        return this.businessunitsService.getByUserId(params.request).pipe(
            tap((result: PagedListModel<BusinessUnitModel>) => {
                context.patchState({
                    businessUnits: result,
                    filter: params.request,
                });
            })
        );
    }

    @Action(BusinessUnitsGetById)
    getById(
        context: StateContext<BusinessUnitsStateModel>,
        params: BusinessUnitsGetById
    ) {
        return this.businessunitsService
            .get(
                new BusinessUnitsGetRequest(
                    null,
                    null,
                    null,
                    params.request?.preschoolInstitutionId
                )
            )
            .pipe(
                tap((result) => {
                    context.patchState({
                        businessUnits: result,
                    });
                })
            );
    }

    @Action(BusinessUnitsUpdate)
    update(
        context: StateContext<BusinessUnitsStateModel>,
        params: BusinessUnitsUpdate
    ) {
        return this.businessunitsService.update(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new BusinessUnitsGet(previousState.filter));
            })
        );
    }

    @Action(BusinessUnitsDelete)
    delete(
        context: StateContext<BusinessUnitsStateModel>,
        params: BusinessUnitsDelete
    ) {
        return this.businessunitsService.delete(params.request).pipe(
            tap(() => {
                const previousState = context.getState();

                context.dispatch(new BusinessUnitsGet(previousState.filter));
            })
        );
    }
}
