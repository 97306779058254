import { DateTime } from 'luxon/src/datetime';
import { BaseRequest } from './base.request';
import { EmployeeTypeEnum } from '../enums/employee-type.enum';
import { AppUserRoleFullModel } from '../app-user-role.model';

export class AppUsersGetRequest implements BaseRequest {
    constructor(
        public UserName: string | null,
        public FirstName: string,
        public LastName: string | null,
        public LoggedUserId: number,
        public RoleLevel: number,
        public Parents: boolean,
        public PreschoolInstitutionId: number | null,
        public BusinessUnitId: number | null,
        public RoleId: number | null,
        public Page: number | null,
        public PageSize: number | null,
        public IsActive: boolean
    ) {}
    toParams(): Object {
        return {
            ...(this.UserName && {
                UserName: this.UserName,
            }),

            ...(this.FirstName && {
                FirstName: this.FirstName,
            }),
            ...(this.LastName && { LastName: this.LastName }),
            LoggedUserId: this.LoggedUserId,
            ...(this.RoleLevel && { RoleLevel: this.RoleLevel }),
            Parents: this.Parents,

            ...(this.PreschoolInstitutionId && {
                PreschoolInstitutionId: this.PreschoolInstitutionId,
            }),

            ...(this.BusinessUnitId && { BusinessUnitId: this.BusinessUnitId }),
            ...(this.RoleId && { RoleId: this.RoleId }),

            IsActive: this.IsActive,

            ...(this.Page && { Page: this.Page }),

            ...(this.PageSize && { PageSize: this.PageSize }),
        };
    }
    toBody(): Object {
        throw new Error('Method not implemented.');
    }
}

export class AppUserGetByUserName implements BaseRequest {
    constructor(public userName: string) {}
    toParams(): Object {
        return {
            userName: this.userName,
        };
    }
    toBody(): Object {
        throw new Error('Method not implemented.');
    }
}

export class AppUserGetByUserId implements BaseRequest {
    constructor(public userId: number) {}
    toParams(): Object {
        return {
            userId: this.userId,
        };
    }
    toBody(): Object {
        throw new Error('Method not implemented.');
    }
}

export class AppUserInsertRoleRequest implements BaseRequest {
    constructor(
        public id: number | null,
        public userId: number,
        public roleId: number,
        public preschoolInstitutionId: number,
        public businessUnitId: number
    ) {}
    toParams(): Object {
        return {
            ...(this.id && { id: this.id }),
            userId: this.userId,
            roleId: this.roleId,
            preschoolInstitutionId: this.preschoolInstitutionId,
            businessUnitId: this.businessUnitId,
            isActive: true,
        };
    }
    toBody(): Object {
        return {
            ...(this.id && { id: this.id }),
            userId: this.userId,
            roleId: this.roleId,
            preschoolInstitutionId: this.preschoolInstitutionId,
            businessUnitId: this.businessUnitId,
            isActive: true,
        };
    }
}
export class AppUserInsertRequest implements BaseRequest {
    constructor(
        public id: number | null,
        public profilePhotoUrl: string,
        public profilePhotoThumbnailUrl: string,
        public firstName: string,
        public lastName: string,
        public userName: string,
        public normalizedUserName: string,
        public email: string,
        public emailConfirmed: boolean,
        public normalizedEmail: string,
        public concurrencyStamp: string,
        public securityStamp: string,
        public password: string,
        public confirmPassword: string,
        public passwordSalt: string,
        public passwordHash: string,
        public phoneNumber: string,
        public alternativePhoneNumber: string,
        public signInCount: number | null,
        public lastSignInAt: DateTime | null,
        public birthDate: DateTime,
        public gender: number,
        public parentshipType: number | null,
        public birthAddressText: string,
        public residentAddressText: string,
        public preschoolInstitutionId: number | null,
        public businessUnitId: number,
        public isEmployed: boolean | null,
        public jobDescription: string,
        public employerName: string,
        public employerAddress: string,
        public employerPhoneNumber: string,
        public isPublic: boolean,
        public qualification: number | null,
        public isCoordinator: boolean,
        public employeeType?: EmployeeTypeEnum | null,
        public preschoolGroupId?: number | null,
        public roles?: AppUserRoleFullModel[] //send roles, check for example in staff management
    ) {}

    toParams(): Object {
        return {
            ...(this.id && {
                id: this.id,
            }),
            profilePhotoUrl: this.profilePhotoUrl,
            profilePhotoThumbnailUrl: this.profilePhotoThumbnailUrl,
            firstName: this.firstName,
            lastName: this.lastName,
            userName: this.userName,
            ...(this.normalizedUserName && {
                normalizedUserName: this.normalizedUserName,
            }),

            email: this.email,
            ...(this.emailConfirmed && {
                emailConfirmed: this.emailConfirmed,
            }),
            ...(this.normalizedEmail && {
                normalizedEmail: this.normalizedEmail,
            }),

            ...(this.concurrencyStamp && {
                concurrencyStamp: this.concurrencyStamp,
            }),

            ...(this.securityStamp && {
                securityStamp: this.securityStamp,
            }),

            password: this.password,

            ...(this.confirmPassword && {
                confirmPassword: this.confirmPassword,
            }),

            ...(this.passwordSalt && {
                passwordSalt: this.passwordSalt,
            }),

            ...(this.passwordHash && {
                passwordHash: this.passwordHash,
            }),

            phoneNumber: this.phoneNumber,
            alternativePhoneNumber: this.alternativePhoneNumber,
            ...(this.signInCount && {
                signInCount: this.signInCount,
            }),

            ...(this.lastSignInAt && {
                lastSignInAt: this.lastSignInAt,
            }),

            birthDate: this.birthDate,
            gender: this.gender,
            ...(this.parentshipType && {
                parentshipType: this.parentshipType,
            }),

            birthAddressText: this.residentAddressText,
            residentAddressText: this.residentAddressText,
            preschoolInstitutionId: this.preschoolInstitutionId,
            ...(this.businessUnitId && { businessUnitId: this.businessUnitId }),

            ...(this.isEmployed && {
                isEmployed: this.isEmployed,
            }),
            ...(this.jobDescription && {
                jobDescription: this.jobDescription,
            }),
            ...(this.employerName && {
                employerName: this.employerName,
            }),
            ...(this.employerAddress && {
                employerAddress: this.employerAddress,
            }),
            ...(this.employerPhoneNumber && {
                employerPhoneNumber: this.employerPhoneNumber,
            }),
            isPublic: this.isPublic,
            ...(this.qualification && {
                qualification: this.qualification,
            }),
            isCoordinator: this.isCoordinator,
            isActive: true,
            ...(this.employeeType && { employeeType: this.employeeType }),
            ...(this.preschoolGroupId && {
                preschoolGroupId: this.preschoolGroupId,
            }),
            ...(this.roles?.length > 0 && { roles: this.roles }),
        };
    }
    toBody(): Object {
        return {
            ...(this.id && {
                id: this.id,
            }),
            profilePhotoUrl: this.profilePhotoUrl,
            profilePhotoThumbnailUrl: this.profilePhotoThumbnailUrl,
            firstName: this.firstName,
            lastName: this.lastName,
            userName: this.userName,
            ...(this.normalizedUserName && {
                normalizedUserName: this.normalizedUserName,
            }),

            email: this.email,
            ...(this.emailConfirmed && {
                emailConfirmed: this.emailConfirmed,
            }),
            ...(this.normalizedEmail && {
                normalizedEmail: this.normalizedEmail,
            }),

            ...(this.concurrencyStamp && {
                concurrencyStamp: this.concurrencyStamp,
            }),

            ...(this.securityStamp && {
                securityStamp: this.securityStamp,
            }),

            password: this.password,

            ...(this.confirmPassword && {
                confirmPassword: this.confirmPassword,
            }),

            ...(this.passwordSalt && {
                passwordSalt: this.passwordSalt,
            }),

            ...(this.passwordHash && {
                passwordHash: this.passwordHash,
            }),

            phoneNumber: this.phoneNumber,
            alternativePhoneNumber: this.alternativePhoneNumber,
            ...(this.signInCount && {
                signInCount: this.signInCount,
            }),

            ...(this.lastSignInAt && {
                lastSignInAt: this.lastSignInAt,
            }),

            birthDate: this.birthDate,
            gender: this.gender,
            //role: this.role,
            ...(this.parentshipType && {
                parentshipType: this.parentshipType,
            }),

            birthAddressText: this.birthAddressText,
            residentAddressText: this.residentAddressText,
            preschoolInstitutionId: this.preschoolInstitutionId,
            businessUnitId: this.businessUnitId,

            ...(this.isEmployed && {
                isEmployed: this.isEmployed,
            }),
            ...(this.jobDescription && {
                jobDescription: this.jobDescription,
            }),
            ...(this.employerName && {
                employerName: this.employerName,
            }),
            ...(this.employerAddress && {
                employerAddress: this.employerAddress,
            }),
            ...(this.employerPhoneNumber && {
                employerPhoneNumber: this.employerPhoneNumber,
            }),
            isPublic: this.isPublic,
            ...(this.qualification && {
                qualification: this.qualification,
            }),
            isCoordinator: this.isCoordinator,
            isActive: true,
            ...(this.employeeType && { employeeType: this.employeeType }),
            ...(this.preschoolGroupId && {
                preschoolGroupId: this.preschoolGroupId,
            }),
            ...(this.roles?.length > 0 && { roles: this.roles }),
        };
    }
}
export class AppUserInsertProfilePictureRequest implements BaseRequest {
    constructor(
        public personId,
        public absouluteUrl: string,
        public thumbnailUrl: string
    ) {}
    toParams(): Object {
        return {
            personId: this.personId,
            absouluteUrl: this.absouluteUrl,
            thumbnailUrl: this.thumbnailUrl,
        };
    }
    toBody(): Object {
        return {
            personId: this.personId,
            absouluteUrl: this.absouluteUrl,
            thumbnailUrl: this.thumbnailUrl,
        };
    }
}
export class AppUserCheckExistRequest implements BaseRequest {
    constructor(public email: string) {}
    toParams(): Object {
        return {
            email: this.email,
        };
    }
    toBody(): Object {
        throw new Error('Method not implemented.');
    }
}
export class AppUserUpdateRequest implements BaseRequest {
    constructor(
        public id: number,
        public firstName: string | null,
        public lastName: string | null,
        public email: string | null,
        public phoneNumber: string | null,
        public alternativePhoneNumber: string | null,
        public birthDate: Date,
        public birthAddressText: string | null,
        public residentAddressText: string | null,
        public isEmployed: boolean | null,
        public jobDescription: string | null,
        public employerName: string | null,
        public employerAddress: string | null,
        public employerPhoneNumber: string | null,
        public qualification: number | null,
        public gender: number | null,
        public isPublic: boolean | null
    ) {}

    toParams(): Object {
        return {};
    }

    toBody(): Object {
        return {
            id: this.id,
            ...(this.firstName && { firstName: this.firstName }),
            ...(this.lastName && { lastName: this.lastName }),
            ...(this.email && { email: this.email }),
            ...(this.phoneNumber && { phoneNumber: this.phoneNumber }),
            ...(this.alternativePhoneNumber && {
                alternativePhoneNumber: this.alternativePhoneNumber,
            }),
            ...(this.birthDate && { birthDate: this.birthDate }),
            ...(this.birthAddressText && {
                birthAddressText: this.birthAddressText,
            }),
            ...(this.residentAddressText && {
                residentAddressText: this.residentAddressText,
            }),
            isEmployed: this.isEmployed,
            ...(this.jobDescription && { jobDescription: this.jobDescription }),

            ...(this.employerName && { employerName: this.employerName }),
            ...(this.employerAddress && {
                employerAddress: this.employerAddress,
            }),
            ...(this.employerPhoneNumber && {
                employerPhoneNumber: this.employerPhoneNumber,
            }),
            ...(this.qualification && { qualification: this.qualification }),
            ...(this.gender && { gender: this.gender }),
            ...(this.isPublic && { isPublic: this.isPublic }),
        };
    }
}

export class AppUserUpdatePasswordRequest implements BaseRequest {
    constructor(
        public userId: number,
        public oldPassword: string,
        public newPassword: string
    ) {}
    toParams(): Object {
        return {
            userId: this.userId,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
        };
    }
    toBody(): Object {
        return {
            userId: this.userId,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
        };
    }
}

export class AppUserUpdateLanguageRequest implements BaseRequest {
    constructor(public language: string) {}
    toParams(): Object {
        return {
            language: this.language,
        };
    }
    toBody(): Object {
        return {
            language: this.language,
        };
    }
}
export class AppUserUpdateEmailRequest implements BaseRequest {
    constructor(
        public userId: number,
        public currentEmail: string,
        public newEmail: string
    ) {}

    toParams(): Object {
        return null;
    }

    toBody(): Object {
        return {
            userId: this.userId,
            currentEmail: this.currentEmail,
            newEmail: this.newEmail,
        };
    }
}

export class AppUsersDeactivateRequest implements BaseRequest {
    constructor(
        public userId: number,
        public roleId: number,
        public preschoolInstitutionId: number | null,
        public businessUnitId: number | null
    ) {}
    toParams(): Object {
        return {
            userId: this.userId,
            roleId: this.roleId,
            ...(this.preschoolInstitutionId && {
                preschoolInstitutionId: this.preschoolInstitutionId,
            }),

            ...(this.businessUnitId && {
                businessUnitId: this.businessUnitId,
            }),
        };
    }
    toBody(): Object {
        return {
            userId: this.userId,
            roleId: this.roleId,
            ...(this.preschoolInstitutionId && {
                preschoolInstitutionId: this.preschoolInstitutionId,
            }),

            ...(this.businessUnitId && {
                businessUnitId: this.businessUnitId,
            }),
        };
    }
}

export class UserRole implements BaseRequest {
    constructor(
        public id: number | null,
        public roleId: number,
        public businessUnitId: number,
        public employeeTypeId: number,
        public preschoolGroupId: number
    ) {}
    toParams(): Object | null {
        return {
            ...(this.id && { id: this.id }),
            roleId: this.roleId,
            ...(this.businessUnitId && { businessUnitId: this.businessUnitId }),
            ...(this.employeeTypeId && { employeeTypeId: this.employeeTypeId }),
            ...(this.preschoolGroupId && {
                preschoolGroupId: this.preschoolGroupId,
            }),
        };
    }
    toBody(): Object | null {
        throw new Error('Method not implemented.');
    }
}

export class AppUserRoleUpdateRequest implements BaseRequest {
    constructor(
        public userId: number,
        public preschoolInstitutionId: number,
        public newRoles: AppUserRoleFullModel[],
        public isPublic: boolean,
        public isCoordinator: boolean
    ) {}
    toParams(): Object | null {
        return {
            userId: this.userId,
            preschoolInstitutionId: this.preschoolInstitutionId,
            ...(this.newRoles?.length > 0 && { newRoles: this.newRoles }),
            isPublic: this.isPublic,
            isCoordinator: this.isCoordinator,
        };
    }
    toBody(): Object | null {
        return {
            userId: this.userId,
            preschoolInstitutionId: this.preschoolInstitutionId,
            ...(this.newRoles?.length > 0 && { newRoles: this.newRoles }),
            isPublic: this.isPublic,
            isCoordinator: this.isCoordinator,
        };
    }
}

export class AppUserInstitutionRoleCheckRequest implements BaseRequest {
    constructor(public userId: number, public preschoolInstitutionId: number) {}
    toParams(): Object | null {
        return {
            userId: this.userId,
            preschoolInstitutionId: this.preschoolInstitutionId,
        };
    }
    toBody(): Object | null {
        return {
            userId: this.userId,
            preschoolInstitutionId: this.preschoolInstitutionId,
        };
    }
}

export class AppUserRolesFullUpsertRequest implements BaseRequest {
    constructor(
        public appUser: AppUserInsertRequest,
        public roles: AppUserRoleUpdateRequest
    ) {}
    toParams(): Object | null {
        return {
            appUser: this.appUser,
            appUserRoles: this.roles,
        };
    }
    toBody(): Object | null {
        return {
            appUser: this.appUser,
            appUserRoles: this.roles,
        };
    }
}
