import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { ApiService, StorageService } from './all';
import {
    BusinessUnitModel,
    PagedListModel,
    SimpleItemModel,
} from '../models/all';
import {
    BusinessUnitsGetRequest,
    BusinessUnitsUpdateRequest,
    BusinessUnitsDeleteRequest,
    GetByUserIdRequest,
    BusinessUnitsGetSelectListRequest,
    BusinessUnitsAvailableForRenewRequest
} from '../models/requests/all';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: BusinessUnitsGetRequest) {
        return this.sendGetRequest<PagedListModel<BusinessUnitModel>>(
            'businessunits/filter',
            request
        );
    }

    getById(request: BusinessUnitsGetRequest) {
        return this.sendGetRequest<BusinessUnitModel>('businessunits', request);
    }

    checkNameIdNumber(name: string, idNumber: string) {
        return this.sendGetRequest<boolean>(`businessunits/check-name-idnumber?name=${name}&idNumber=${idNumber}`);
    }

    getByUserId(request: GetByUserIdRequest) {
        return this.sendGetRequest('businessunits/getbyuserid', request);
    }

    getSelectList(request: BusinessUnitsGetSelectListRequest) {
        return this.sendGetRequest<SimpleItemModel[]>(
            'businessunits/select-list',
            request
        );
    }

    upsertFormData(request: FormData) {
        return this.sendFormRequest<BusinessUnitModel>(
            'businessunits/upsert-formdata',
            request
        );
    }

    update(request: BusinessUnitsUpdateRequest) {
        return this.sendPutRequest('businessunits', request);
    }

    delete(request: BusinessUnitsDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('businessunits', request);
    }

    getAvailableForRenew(request: BusinessUnitsAvailableForRenewRequest) {
        return this.sendGetRequest<BusinessUnitModel[]>(
            'businessunits/get-available-for-renew',
            request
        );
    }
}
