import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import {
    DevelopmentalProgramEnrollmentDismissRequest,
    DevelopmentalProgramEnrollmentGetRequest,
    DevelopmentalProgramEnrollmentUpsertRequest,
} from 'app/models/requests/developmental-program-enrollment-request';
import { PagedListModel } from 'app/models/paged-list.model';
import { DevelopmentalProgramEnrollmentModel } from 'app/models/developmental-program-enrollment.model';

@Injectable({ providedIn: 'root' })
export class DevelopmentalProgramEnrollmentsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: DevelopmentalProgramEnrollmentGetRequest) {
        return this.sendGetRequest<
            PagedListModel<DevelopmentalProgramEnrollmentModel>
        >('developmentalprogramsenrollments/filter', request);
    }

    insert(request: DevelopmentalProgramEnrollmentUpsertRequest) {
        return this.sendPostRequest(
            'developmentalprogramsenrollments',
            request
        );
    }

    dismiss(request: DevelopmentalProgramEnrollmentDismissRequest) {
        return this.sendPostRequest(
            'developmentalprogramsenrollments/dismiss',
            request
        );
    }

    pendingEnrollments(preschoolInstitutionId: number) {
        return this.sendGetRequest<number>(
            'developmentalprogramsenrollments/pending-enrollments-count?preschoolInstitutionId=' +
                preschoolInstitutionId
        );
    }
}
