export const environment = {
    production: true,
    defaultLanguage: 'bs',
    maxFilesNumber: 30,
    maxFileSize: 1024,
    imageCompressionStartingQuality: 0.9,
    imageCompressionQualityStep: 0.1,
    appName: 'eVrtic',
    baseApiUrl: 'https://api.preschool.breakpoint.ba/api',
    hub: 'https://api.preschool.breakpoint.ba/Hub',
    localizationFilesPath: '`../assets/i18n/',
    localizationApiUrl: 'https://localization.breakpoint.ba/api/translations',
    publicWebPagesPrefix: 'vrtic',
    recaptcha: {
        siteKey: '6LdNIWEqAAAAABhJpRTKJewzffT1UXN90RJpBmjG',
    },
    preschoolData: {
        phoneNumber: '061/234-567',
        email: 'info@evrtic.ba',
        address: 'Trg Ivana Krndelja b.b.',
        city: 'Mostar 88000, BiH'
    }
};
