import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { FuseLoadingService } from '@fuse/services/loading/loading.service';

@Injectable()
export class FuseLoadingInterceptor implements HttpInterceptor {
    handleRequestsAutomatically: boolean;
    urlMethods: Map<string, string> = new Map([['chatbotmessages', 'post']]);
    keys: string[] = Array.from(this.urlMethods.keys());
    /**
     * Constructor
     */
    constructor(private _fuseLoadingService: FuseLoadingService) {
        // Subscribe to the auto
        this._fuseLoadingService.auto$.subscribe((value) => {
            this.handleRequestsAutomatically = value;
        });
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // If the Auto mode is turned off or if it is sending specific message, do nothing
        if (
            !this.handleRequestsAutomatically ||
            this.keys.some(
                (key) =>
                    req.url.includes(key) &&
                    this.urlMethods.get(key) === req.method.toLowerCase()
            )
        ) {
            return next.handle(req);
        }

        // Set the loading status to true
        this._fuseLoadingService._setLoadingStatus(true, req.url);

        return next.handle(req).pipe(
            finalize(() => {
                // Set the status to false if there are any errors or the request is completed
                this._fuseLoadingService._setLoadingStatus(false, req.url);
            })
        );
    }
}
