<div class="flex flex-col min-w-0">
    <div class="flex justify-between font-medium truncate">
        <b class="text-sm sm:text-xl flex-auto">
            {{ model.eventType === weeklyActivityEnum 
                ? (model.activityName ? model.activityName : (model.name | translate ))
                : (model.name | translate) 
            }}
            <div *ngIf="model.eventType != weeklyActivityEnum" class="text-sm max-w-xs truncate mt-1">
                {{ model.eventType }} • {{ model.dateOfEvent | dateTimeFormat }}
            </div>
            <div class="text-sm max-w-xs truncate mt-1" *ngIf="model.eventType == weeklyActivityEnum">
                {{ model.dateOfEvent | dateFormat }}
            </div>
        </b>

        <button (click)="closeDialog()" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</div>
<hr />
<div *ngIf="model.eventType !== weeklyActivityEnum">
    <p class="mt-3" [innerHTML]="model.description"></p>
</div>
<div *ngIf="model.eventType === weeklyActivityEnum">
    <ng-container *ngFor="let activity of model.dailyActivities">
        <p class="mt-3" *ngIf="activity.description">
            <span><b>{{ activity.weeklyActivityType?.name }}</b></span><br />
            <span [innerHTML]="activity.description"></span>
        </p>
    </ng-container>
</div>

<div class="grid gap-2 mb-5" *ngIf="model?.files?.length > 0 || model?.fullFiles?.length > 0">
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-4 py-2 pe-2">
        <div
            (click)="openDetails(model?.files, item.absouluteUrl)"
            class="flex mr-2 justify-content-start items-center cursor-pointer"
            *ngFor="let item of model?.fullFiles; let i = index"
        >
            <img
                *ngIf="
                    item.absouluteUrl.includes('jpg') ||
                        item.absouluteUrl.includes('png') ||
                        item.absouluteUrl.includes('jpeg') ||
                        item.absouluteUrl.includes('jfif') ||
                        item.absouluteUrl.includes('avif');
                    else filePic
                "
                class="w-10 h-10 rounded-md"
                [src]="item.absouluteUrl"
                alt=""
            />

            <ng-template #filePic>
                <img
                    class="w-10 h-10 rounded-md"
                    src="assets/img/annoucements/open-folder.png"
                    alt="File Icon"
                />
            </ng-template>
            <div class="ml-3 flex flex-col justify-center">
                <div
                    class="text-md font-medium truncate w-28 removeFontFileName text-black"
                >
                    {{ item.fileName | truncate : 30 }}
                </div>
                <div
                    class="text-sm font-medium truncate text-secondary removeFontFileName"
                    *ngIf="item.fileLength != null"
                >
                    {{ item.fileLength.toString() | fileSizeConverter }}
                </div>
            </div>
        </div>
    </div>
</div>
<hr />
<div class="flex items-center">
    <span>
        <img
            [src]="model.coordinatorProfilePhotoThumbnailUrl"
            class="w-5 h-5 object-cover rounded-full photo-cover"
        />
    </span>
    <span class="font-semibold px-2 text-sm">{{
        model.coordinatorFullName
    }}</span>
    <span *ngIf="model.businessUnitName; else showInstitutionName" class="font-semibold text-sm">• {{ model.businessUnitName }}</span>
    <ng-template #showInstitutionName>
        <span class="font-semibold text-sm">
          • {{ model.preschoolInstitutionName }}
        </span>
    </ng-template>
</div>
