import {
    Component,
    OnInit,
    OnDestroy,
    Inject,
    HostListener,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { AuthService } from 'app/core/auth/auth.service';
import { ChildPaymentModel } from 'app/models/child-payment.model';
import { ActiveRoleEnum } from 'app/models/enums/roles.enum';
import { StorageService } from 'app/services/storage.service';
import { UnSub } from 'app/shared/extensions/unSub';
import { AuthState } from 'app/stores/all';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class DetailsComponent extends UnSub implements OnInit, OnDestroy {
    constructor(
        /* _transfer can be announcement or event detail.. */
        @Inject(MAT_DIALOG_DATA)
        public _transfer: {
            files: string[];
            currentPicture: string;
            payment: ChildPaymentModel;
        },
        public _dialogRef: MatDialogRef<DetailsComponent>,
        public _storage: StorageService,
        public _store: Store,
        private _authService: AuthService
    ) {
        super();
        this.isEducator = this._authService.isEducator();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent): void {
        if (event.key === 'ArrowLeft') {
            this.goToPrevious();
        }
        if (event.key === 'ArrowRight') {
            this.goToNext();
        }
    }

    currentPicture = '';
    shouldShowArrows: boolean;
    payment: ChildPaymentModel;
    isEducator: boolean;
    // --------------------------------------------------------------
    // @ Lifecycle hooks
    // --------------------------------------------------------------

    ngOnInit(): void {
        if (this.isEducator) {
            this.payment = this._transfer.payment;
        }
        this.currentPicture = this._transfer.currentPicture;

        this.currentIndex = this._transfer?.files?.findIndex(
            (item) => item == this.currentPicture
        );

        this.shouldShowArrows = this._transfer?.files?.length > 1;
    }

    // ---------------------------------------------------------------
    // @ Public methods
    // ---------------------------------------------------------------

    currentIndex = 0;
    timeoutId?: number;

    ngOnDestroy() {
        window.clearTimeout(this.timeoutId);
    }

    closeDialog() {
        this._dialogRef.close();
    }

    goToPrevious(): void {
        if (this.currentIndex > 0) {
            const newIndex = this.currentIndex - 1;
            this.currentPicture = this._transfer.files[newIndex];
            this.currentIndex = newIndex;
        } else {
            const newIndex = this._transfer.files.length - 1;
            this.currentPicture = this._transfer.files[newIndex];
            this.currentIndex = newIndex;
        }
    }

    goToNext(): void {
        if (this.currentIndex < this._transfer.files.length - 1) {
            const newIndex = this.currentIndex + 1;
            this.currentPicture = this._transfer.files[newIndex];
            this.currentIndex = newIndex;
        } else {
            this.currentPicture = this._transfer.files[0];
            this.currentIndex = 0;
        }
    }
}
