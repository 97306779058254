import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarTypeEnum } from 'app/models/enums/snackbar-type.enum';

@Component({
    selector: 'my-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
    @Output() actionClicked = new EventEmitter<string>();
    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            message: string;
            snackType?: SnackBarTypeEnum;
            action?: string;
        }
    ) {}

    ngOnInit() {}

    get getIcon() {
        switch (this.data.snackType) {
            case SnackBarTypeEnum.Success:
                return 'check_circle';
            case SnackBarTypeEnum.Error:
                return 'error';
            case SnackBarTypeEnum.Alert:
                return 'warning';
            default:
                return 'info';
        }
    }
}
