import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarTypeEnum } from 'app/models/enums/snackbar-type.enum';
import { SnackbarComponent } from 'app/shared/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) {}

    showSnackBar(
        message: string,
        type?: SnackBarTypeEnum,
        duration?: number,
        action?: string
    ): void {
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: duration ?? 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            data: {
                message: message,
                snackType: type ?? SnackBarTypeEnum.Success,
                action: action,
            },
            panelClass: [`snack-${type ?? 'success'}`],
        });
    }
}
