import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, take, takeUntil } from 'rxjs';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'app/services/storage.service';
import { AppUsersService } from 'app/services/app-users.service';
import { AppUserUpdateLanguageRequest } from 'app/models/requests/app-users.request';
import { Store } from '@ngxs/store';
import { AuthState } from 'app/stores/all';
import { PreschoolInstitutionsService } from 'app/services/preschool-institutions.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    flagCodes: any;
    pageName: string;

    /**
     * Constructor
     */
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _translateService: TranslateService,
        private _storageService: StorageService,
        private _appUsersService: AppUsersService,
        private _store: Store,
        private _preschoolInstitutionsService: PreschoolInstitutionsService,
        private _activatedRoute: ActivatedRoute
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.activeLang.next(activeLang);
            //this.activeLang = this._translateService.currentLang;
            // Update the navigation
            this._updateNavigation(activeLang);
        });
        // Set the country iso codes for languages for flags
        this.flagCodes = {
            en: 'en',
            bs: 'bs',
            hr: 'hr'
        };
        if(this._store.selectSnapshot(AuthState.details) != null){
            this._appUsersService.getUsersLanguage().subscribe((data: string) => {
                this.setActiveLang(data, true);
            });
        }else{
            this._activatedRoute.paramMap
            .pipe()
            .subscribe((params) => {
                this.pageName = params.get('pageName');

                if (!this.pageName) {
                    // If pageName is null, set default language to 'bs'
                    this.setActiveLang('bs', true);
                } else {
                    this._preschoolInstitutionsService.getPublic(this.pageName).subscribe((result) => {
                        this.setActiveLang(result.language.languageCode, true);
                    });
                }
        });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(language: string, skipUpdate?:boolean): void {
        // Set the active lang
        this._storageService.setItem('lang', language);
        this._translateService.use(language);
        this._translocoService.setActiveLang(language);
        this._translocoService.load(language);
        
        this.activeLang.next(language);
        if(this._store.selectSnapshot(AuthState.details) != undefined && !skipUpdate){
            this._appUsersService.changeUserLanguage(new AppUserUpdateLanguageRequest(language)).subscribe();
        }
    }
    unsubscribe$(unsubscribe$: any): import("rxjs").OperatorFunction<import("@angular/router").ParamMap, import("@angular/router").ParamMap> {
        throw new Error('Method not implemented.');
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.
        
        // Get the component -> navigation data -> item
        const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                'mainNavigation'
            );

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Project dashboard item and update its title
        const projectDashboardItem = this._fuseNavigationService.getItem(
            'dashboards.project',
            navigation
        );
        if (projectDashboardItem) {
            this._translocoService
                .selectTranslate('Project')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    projectDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Analytics dashboard item and update its title
        const analyticsDashboardItem = this._fuseNavigationService.getItem(
            'dashboards.analytics',
            navigation
        );
        if (analyticsDashboardItem) {
            this._translocoService
                .selectTranslate('Analytics')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    analyticsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
    }
}
