import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { activeRole, institutionId } from 'app/constants/constants';
import { RoleEnum } from 'app/models/enums/roles.enum';
import { UserModel } from 'app/models/user.model';
import { AppUsersService } from 'app/services/app-users.service';
import { NavigationRoleService } from 'app/services/navigation-role.service';
import { StorageService } from 'app/services/storage.service';
import { ActiveRoleGet, AuthRefresh, AuthState } from 'app/stores/all';
import { slice } from 'lodash';
import { take, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-choose-roles',
    templateUrl: './choose-roles.component.html',
    styleUrls: ['./choose-roles.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChooseRolesComponent implements OnInit {
    constructor(
        public _dialogRef: MatDialogRef<ChooseRolesComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: UserModel,
        public _store: Store,
        private _roleNavigationService: NavigationRoleService,
        private _storageService: StorageService,
        private _appUsersService: AppUsersService
    ) {}

    groupedRoles: { [key: number]: { InstitutionName: string; Roles: any[] } } =
        {};
    selectedRole: number;
    roleEnum = RoleEnum;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void {
        const roles = this._data.AspNetUserRoles;

        this.groupedRoles = roles.reduce((groups, role) => {
            const institutionId = role.InstitutionId;
        
            if (!groups[institutionId]) {
                groups[institutionId] = {
                    InstitutionName: role.InstitutionName,
                    Roles: [],
                    ParentRoleIds: new Set(), 
                };
            }
        
            if (role.RoleId == RoleEnum.Parent) {
                if (!groups[institutionId].ParentRoleIds.has(role.RoleId)) {
                    groups[institutionId].Roles.push(role);
                    groups[institutionId].ParentRoleIds.add(role.RoleId);
                }
            } else {
                groups[institutionId].Roles.push(role);
            }
        
            return groups;
        }, {});

        let institutionId = this._storageService.getInstitutionId();
        if (institutionId) {
            let businessUnitId = this._storageService.getUnitId();
            let activeRole = this._storageService.getActiveRole();
            this.selectedRole = roles.find(
                (r) =>
                    r.InstitutionId == institutionId &&
                    (businessUnitId == null || r.UnitId == businessUnitId) &&
                    r.Role?.toLowerCase() == activeRole
            )?.Id;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    submitRole(x: number) {
        if (this._data.AspNetUserRoles.find((r) => r.Id == x) == null) {
            return;
        } else {
            var roleId = this._data.AspNetUserRoles.find(
                (r) => r.Id == x
            ).RoleId;
            this._appUsersService
                .updateActiveRole(roleId)
                .pipe(take(1))
                .subscribe();

            const institutionIdNum = this._data.AspNetUserRoles.find(
                (r) => r.Id == x
            ).InstitutionId;

            const unitId = this._data.AspNetUserRoles.find(
                (r) => r.Id == x
            ).UnitId;

            if (institutionIdNum != null) {
                this._storageService.setItem(
                    institutionId,
                    institutionIdNum.toString()
                );

                this._store.dispatch(
                    new AuthRefresh({
                        ...this._store.selectSnapshot(AuthState.details),
                        InstitutionId: institutionIdNum.toString(),
                    })
                );
            }

            const isAdminRole =
                roleId === RoleEnum.SuperAdministrator ||
                roleId === RoleEnum.PreschoolInstitutionAdministrator ||
                roleId === RoleEnum.PreschoolInstitutionManager;

            const isUnitAssigned = unitId !== null;

            if (unitId != null) {
                this._storageService.setItem('unitId', unitId?.toString());
            } else {
                this._storageService.removeItem('unitId');
            }

            this._store
                .dispatch(new ActiveRoleGet(roleId))
                .pipe(
                    tap((role: any) => {
                        this._store.dispatch(
                            new AuthRefresh({
                                ...this._store.selectSnapshot(
                                    AuthState.details
                                ),
                                ActiveRole: role.activeRole?.activeRole?.label,
                            })
                        );

                        // postavljanje trenutne školske godine na instituciji!
                        this._storageService.setItem(
                            'currentSchoolYear',
                            this._store
                                .selectSnapshot(AuthState.details)
                                .SchoolYearId.toString()
                        );

                        this._storageService.setItem(
                            activeRole,
                            JSON.stringify(role.activeRole?.activeRole?.label)
                        );
                    })
                )
                .subscribe((_) => {
                    this._roleNavigationService.getRoleNavigation();

                    this.closeDialog();
                });
        }
    }

    closeDialog() {
        this._dialogRef.close();
    }
}
