<div class="absolute inset-0">
    <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10">
        <div class="max-w-3/4 md:max-w-96">
            <img src="assets/images/ui/401.svg" />
        </div>
        <div
            class="text-4xl md:text-7xl font-extrabold tracking-tight leading-tight text-center heading-text"
        >{{ 'Unauthorized' | translate }}</div>
        <div
            class="tracking-tight leading-tight md:leading-none text-center mt-6"
        >{{ 'NoPermission' | translate }}</div>
        <div>
            <button
                class="mt-6 px-4 md:px-6 py-1 md:py-3 button-background text-white rounded-md uppercase text-base md:text-lg"
                (click)="goBack()"
            >{{ 'BackToHome' | translate }}</button>
        </div>
    </div>
</div>