import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { StorageService } from 'app/services/all';
import { AuthState } from 'app/stores/all';

@Injectable({
    providedIn: 'root',
})
export class ParentGuard implements CanActivate {
    constructor(
        private _store: Store,
        private _storage: StorageService,
        private _router: Router
    ) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (
            this._storage.getActiveRole() != 'parent' &&
            this._store.selectSnapshot(AuthState.details)?.Children?.length == 0 &&
            this._store.selectSnapshot(AuthState.details)?.BusinessUnits?.length == 0 //ako ima djecu, a nema upisanu djecu ima business unite dodate
        ) {
            this._router.navigate(['sign-in']);
            return false;
        }
        return true;
    }
}
