import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    MessagesGetFilteredRequest,
    MessagesGetForGroupFilteredRequest,
    MessagesInsertRequest,
} from 'app/models/requests/all';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { MessageGetFilteredModel } from 'app/models/message-filtered.model';

@Injectable({
    providedIn: 'root',
})
export class MessagesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    insert(request: MessagesInsertRequest) {
        return this.sendPostRequest('messages', request);
    }

    getFiltered(request: MessagesGetFilteredRequest) {
        return this.sendGetRequest<MessageGetFilteredModel>(
            'messages/getfiltered',
            request
        );
    }

    getForGroupFiltered(request: MessagesGetForGroupFilteredRequest) {
        return this.sendGetRequest<MessageGetFilteredModel>(
            'messages/getfilteredforgroup',
            request
        );
    }
}
