import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { ApiService, StorageService } from './all';
import {
    PagedListModel,
    PreschoolInstitutionModel,
    SimpleItemModel,
} from '../models/all';
import {
    PreschoolInstitutionsGetRequest,
    PreschoolInstitutionsInsertRequest,
    PreschoolInstitutionsUpdateRequest,
    PreschoolInstitutionsDeleteRequest,
    GetByUserIdRequest,
    PreschoolInstitutionsExistsByInternalUrlRequest,
    PreschoolInstitutionsClientsGetRequest,
} from '../models/requests/all';
import { PreschoolInstitutionPublicCheckModel } from 'app/models/preschool-institution-public-check.model';
import { PreschoolInstitutionPublicInfoModel } from 'app/models/preschool-institution-public-info.model';

@Injectable({
    providedIn: 'root',
})
export class PreschoolInstitutionsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: PreschoolInstitutionsGetRequest) {
        return this.sendGetRequest<PagedListModel<PreschoolInstitutionModel>>(
            'preschoolinstitutions/filter',
            request
        );
    }

    getPublic(internalUrl: string) {
        return this.sendGetRequest<PreschoolInstitutionModel>(
            'preschoolinstitutions/getbyinternalurl?url=' + internalUrl
        );
    }

    getPublicInfo(id: number){
        return this.sendGetRequest<PreschoolInstitutionModel>(
            'preschoolinstitutions/getpublicpageinfo?id='+id
        )
    }

    getAboutUs(id: number){
        return this.sendGetRequest<PreschoolInstitutionPublicInfoModel>('preschoolinstitutions/aboutus?id='+id)
    }

    getWorkProgramme(id: number){
        return this.sendGetRequest<PreschoolInstitutionPublicInfoModel>('preschoolinstitutions/workprogramme?id='+id)
    }
    
    getPublicCheckExists(id:number){
        return this.sendGetRequest<PreschoolInstitutionPublicCheckModel>(
            'preschoolinstitutions/checkpublicexists?id='+id
        )
    }

    getClients(request: PreschoolInstitutionsClientsGetRequest) {
        return this.sendGetRequest<PagedListModel<PreschoolInstitutionModel>> ('preschoolinstitutions/getclients', request);
    }

    getById(request: PreschoolInstitutionsGetRequest) {
        return this.sendGetRequest<PreschoolInstitutionModel>(
            'preschoolinstitutions',
            request
        );
    }

    getByUserId(request: GetByUserIdRequest) {
        return this.sendGetRequest(
            'preschoolinstitutions/getbyuserid',
            request
        );
    }

    getSelectList() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'preschoolinstitutions/select-list'
        );
    }

    insert(request: PreschoolInstitutionsInsertRequest) {
        return this.sendPostRequest<PreschoolInstitutionModel>(
            'preschoolinstitutions',
            request
        );
    }

    upsertInstitutionUserAndFormData(request: FormData) {
        return this.sendFormRequest<PreschoolInstitutionModel>(
            'preschoolinstitutions/upsert-institution-user-formdata',
            request
        );
    }

    update(request: PreschoolInstitutionsUpdateRequest) {
        return this.sendPutRequest('preschoolinstitutions', request);
    }

    delete(request: PreschoolInstitutionsDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('preschoolinstitutions', request);
    }

    existsByInternalUrl(request: PreschoolInstitutionsExistsByInternalUrlRequest): Observable<boolean> {
        return this.sendGetRequest<boolean>('preschoolinstitutions/existsbyinternalurl', request);
    }

    checkIdNumberExists(idNumber: string){
        return this.sendGetRequest<boolean>(
            `preschoolinstitutions/checkidnumberexists?idNumber=${idNumber}`
        );
    }
}
