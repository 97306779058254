import { AuthState } from './auth/auth.state';
import { CitiesState } from './cities/cities.state';
import { CountriesState } from './countries/countries.state';
import { PreschoolInstitutionsState } from './preschool-institutions/preschool-institutions.state';
import { BusinessUnitsState } from './business-units/business-units.state';
import { EnumsState } from './enums/enums.state';
import { ProgramsState } from './programs/programs.state';
import { DocumentTypesState } from './document-types/document-types.state';
import { PreschoolDocumentTypesState } from './preschool-document-types/preschool-document-types.state';
import { AppUsersState } from './app-users/app-users.state';
import { ActiveRoleState } from './active-role/active-role.state';
import { SchoolYearsState } from './all';
import { InstitutionsDocumentsState } from './all';
import { AnnouncementsState } from './announcements/announcements.state';
import { EventTypesState } from './all';
import { EventsState } from './all';
import { ChatsState } from './chat/chat.state';
import { EvaluationItemsState } from './evaluation-items/evaluation.items.state';
import { ActivitiesState } from './all';
import { InstitutionActivitiesState } from './all';
import { NonAuthState } from './non-auth/non-auth.state';

export * from './auth/auth.actions';
export * from './auth/auth.state';

export * from './cities/cities.actions';
export * from './cities/cities.state';

export * from './countries/countries.actions';
export * from './countries/countries.state';

export * from './preschool-institutions/preschool-institutions.actions';
export * from './preschool-institutions/preschool-institutions.state';

export * from './business-units/business-units.actions';
export * from './business-units/business-units.state';

export * from './enums/enums.actions';
export * from './enums/enums.state';

export * from './programs/programs.actions';
export * from './programs/programs.state';

export * from './document-types/document-types.actions';
export * from './document-types/document-types.state';

export * from './preschool-document-types/preschool-document-types.actions';
export * from './preschool-document-types/preschool-document-types.state';

export * from './app-users/app-users.actions';
export * from './app-users/app-users.state';

export * from './active-role/active-role.actions';
export * from './active-role/active-role.state';

export * from './school-years/school-years.actions';
export * from './school-years/school-years.state';

export * from './institution-documents/institution-documents.actions';
export * from './institution-documents/institution-documents.state';

export * from './announcements/announcements.actions';
export * from './announcements/announcements.state';

export * from './event-types/event-types.actions';
export * from './event-types/event-types.state';

export * from './events/events.actions';
export * from './events/events.state';

export * from './chat/chat.actions';
export * from './chat/chat.state';

export * from './evaluation-items/evaluation-items.actions';
export * from './evaluation-items/evaluation.items.state';

export * from './activity-types/activity-types.actions';
export * from './activity-types/activity-types.state';

export * from './institution-activities/institution-activities.actions';
export * from './institution-activities/institution-activities.state';

export * from './non-auth/non-auth.actions';
export * from './non-auth/non-auth.state';

export const States = {
    all: [
        AuthState,
        CitiesState,
        CountriesState,
        PreschoolInstitutionsState,
        BusinessUnitsState,
        EnumsState,
        ProgramsState,
        DocumentTypesState,
        PreschoolDocumentTypesState,
        AppUsersState,
        AuthState,
        ActiveRoleState,
        SchoolYearsState,
        InstitutionsDocumentsState,
        AnnouncementsState,
        EventTypesState,
        EventsState,
        ChatsState,
        EvaluationItemsState,
        ActivitiesState,
        InstitutionActivitiesState,
        NonAuthState
    ],
    auth: AuthState,
};
